<template>
  <layout-auth>
    <div class="px-138">
      <div class="my-96">
        <img src="@/assets/images/logo/dashboard-logo.svg" alt="">
      </div>
      <app-heading-1 class="">Forget Password?</app-heading-1>
      <app-normal-text class="mb-2">Enter your email, and we'll send you instructions to reset your password</app-normal-text>
      <b-alert variant="danger" :show="errorMessage.length" class="p-1">{{errorMessage}}</b-alert>
      <validation-observer ref="resetForm" >
        <div class="row">
          <div class="col-12">
            <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
              <app-text-input label="Email Address" v-model="user_data.email" placeholder="jhon.doe@gmail.com" :errors="errors"/>
            </validation-provider> 
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
              <app-simple-button variant="primary" :loading="loading" class="mb-16" @click="sendResetLink">Send Reset Link</app-simple-button>
              <app-normal-text class="align-middle">
                <b-link to="/admin/login"><feather-icon icon="ChevronLeftIcon" size="20"/> Back to log in</b-link>
              </app-normal-text>
          </div>
        </div>
      </validation-observer>
    </div>
  </layout-auth>
</template>

<script>
import LayoutAuth from '@/layouts/layout-auth.vue'
import {
  BLink,BFormCheckbox, BFormGroup, BAlert
} from 'bootstrap-vue'
import AppHeading1 from '@/components/app-components/AppHeading1.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppPasswordInput from '@/components/app-components/AppPasswordInput.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {password_reset_link} from '@/apis/auth'
export default {
  components: {
    LayoutAuth,
    BLink,
    BFormCheckbox,
    BFormGroup,
    AppHeading1,
    AppNormalText,
    AppTextInput,
    AppPasswordInput,
    AppSimpleButton,
    ValidationProvider,
    ValidationObserver,
    BAlert
  },
  data() {
    return {
      user_data:{
        email:'',

      },
      required,
      email,
      loading:false,
      errorMessage:''       
    }
  },
  methods: {
    sendResetLink() {
      this.errorMessage=''
      this.$refs.resetForm.validate().then(success=>{
        if(!success){
          this.errorMessage = 'Please fill all the required fields & enter valid data'
          return
        }
        this.loading = true
        password_reset_link(this.user_data).then(resp=>{
              this.loading=false;
              this.$bvToast.toast(resp.message, {
                title: 'Link sent successfully',
                variant: 'success',
                solid: true,
              })

        }).catch(err=>{
          this.loading=false;
          this.errorMessage = err.message
        })
      })
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
